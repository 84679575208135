import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import emailconfirm from "../images/emailconfirmed.gif"
import emailrecieved from "../images/emailrecieved.gif"
import { useSelector } from "react-redux"

function Index() {
  const token = useSelector((state) => state.user.token)

  return (
    <Wrapper>
      {token ? (
        <div className="email_confirm_wrapper">
          <div className="email_confirm_image">
            <img src={emailconfirm} alt="certisured email confirm" />
          </div>
          <h1 className="email_confirm_h1">
            you've confirmed the email so you can login
          </h1>
          <Link to="/login">log in</Link>
        </div>
      ) : (
        <div className="email_recieved_wrapper">
          <div className="email_confirm_image">
            <img src={emailrecieved} alt="certisured email confirm" />
          </div>
          <h1 className="email_confirm_h2">
            You may have recieved the Email please check it and confirm to Login
          </h1>
        </div>
      )}
    </Wrapper>
  )
}

export default Index

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  @media (min-width: 767px) {
    min-height: 600px;
  }
  .email_confirm_wrapper,
  .email_recieved_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .email_confirm_image {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        @media (min-width: 767px) {
          width: 70%;
        }
      }
    }
    .email_confirm_h1 {
      color: #000 !important;
      text-align: center;
      font-size: 1rem;
      @media (max-width: 479px) {
        font-size: 1rem;
      }
    }
  }

  a {
    background-color: var(--thirdColor);
    color: var(--secondaryColor);
    min-width: 150px;
    padding: 15px 25px;
    outline: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
  }
  .email_confirm_h2 {
    color: #000 !important;
    text-align: center;
    font-size: 1rem;
    @media (max-width: 479px) {
      font-size: 1rem;
    }
  }
`
